// https://ads.tiktok.com/help/article?aid=10028
// Deduplication: https://ads.tiktok.com/marketing_api/docs?id=1723170195197953
// TODO check all events and add more if there are any

/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load TikTok Ads event listeners
 * */

// Pixel load event listener
jQuery(document).on("wpmLoadPixels", () => {

	if (wpmDataLayer?.pixels?.tiktok?.pixel_id && !wpmDataLayer?.pixels?.tiktok?.loaded) {
		if (wpm.canIFire("ads", "tiktok-ads")) wpm.loadTikTokPixel()
	}
})

// AddToCart event
jQuery(document).on("wpmClientSideAddToCart", (event, payload) => {

	try {
		if (!wpmDataLayer?.pixels?.tiktok?.loaded) return

		ttq.track(
			payload.tiktok.event,
			payload.tiktok.properties,
			{event_id: payload.tiktok.event_id},
		)

	} catch (e) {
		console.error(e)
	}
})

// InitiateCheckout event
jQuery(document).on("wpmClientSideBeginCheckout", (event, payload) => {

	try {
		if (!wpmDataLayer?.pixels?.tiktok?.loaded) return

		ttq.track(
			payload.tiktok.event,
			payload.tiktok.properties,
			{event_id: payload.tiktok.event_id},
		)
	} catch (e) {
		console.error(e)
	}
})

// ViewContent event
jQuery(document).on("wpmClientSideViewItem", (event, payload) => {

	try {
		if (!wpmDataLayer?.pixels?.tiktok?.loaded) return

		ttq.track(
			payload.tiktok.event,
			payload.tiktok.properties,
			{event_id: payload.tiktok.event_id},
		)

	} catch (e) {
		console.error(e)
	}
})

// AddToWishlist event
jQuery(document).on("wpmClientSideAddToWishlist", (event, payload) => {

	try {
		if (!wpmDataLayer?.pixels?.tiktok?.loaded) return

		ttq.track(
			payload.tiktok.event,
			payload.tiktok.properties,
			{event_id: payload.tiktok.event_id},
		)

	} catch (e) {
		console.error(e)
	}
})

// search event
jQuery(document).on("wpmClientSideSearch", (event, payload) => {

	try {
		if (!wpmDataLayer?.pixels?.tiktok?.loaded) return

		ttq.track(
			payload.tiktok.event,
			payload.tiktok.properties,
			{event_id: payload.tiktok.event_id},
		)

	} catch (e) {
		console.error(e)
	}

})

// PlaceAnOrder event
// https://ads.tiktok.com/help/article/standard-events-parameters
jQuery(document).on("wpmClientPlaceOrder", (event, payload) => {

	try {
		if (!wpmDataLayer?.pixels?.tiktok?.loaded) return

		ttq.track(
			payload.tiktok.event,
			payload.tiktok.properties,
			{event_id: payload.tiktok.event_id},
		)
	} catch (e) {
		console.error(e)
	}

})

// view order received page event
jQuery(document).on("wpmClientSideOrderReceivedPage", (event, payload) => {

	try {
		if (!wpmDataLayer?.pixels?.tiktok?.loaded) return

		ttq.track(
			payload.tiktok.event,
			payload.tiktok.properties,
			{event_id: payload.tiktok.event_id},
		)

	} catch (e) {
		console.error(e)
	}
})
