/**
 * Copyright SweetCode. All rights reserved.
 *
 * Load VWO event listeners
 * */

// Pixel load event listener
jQuery(document).on("wpmLoadPixels", () => {

	if (wpmDataLayer?.pixels?.vwo?.account_id && !wpmDataLayer?.pixels?.vwo?.loaded) {
		if (wpm.canIFire("analytics", "vwo")) wpm.loadVwoPixel()
	}
})

// view order received page event
jQuery(document).on("wpmOrderReceivedPage", () => {

	try {
		if (!wpmDataLayer?.pixels?.vwo?.loaded) return

		window.VWO.push([
			'track.revenueConversion',
			wpmDataLayer.order.value.total,
		]);

	} catch (e) {
		console.error(e)
	}
})

