/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Automatic Conversion Recovery (ACR)
 */
jQuery(document).on("wpmLoadAlways", async () => {

	try {
		// Return if on purchase confirmation page
		if (wpmDataLayer?.shop?.page_type === "order_received_page") return

		wpm.log("ACR check running")

		// Return if ACR cookie has not been set
		if (!wpm.getCookie("pmw_automatic_conversion_recovery")) return

		wpm.log("ACR cookie found")

		if (wpm.retrieveData("acr_attempted")) return

		wpm.log("ACR check not run in this session")

		// Get the order details from the server
		await wpm.getAcrOrderDetails()

		if (!wpmDataLayer?.order) {
			wpm.log("Pixel Manager: ACR order details not retrieved")
			wpm.storeData("acr_attempted", true)
			return
		}

		wpm.log("ACR order details retrieved")
		wpm.log("wpmDataLayer.order", wpmDataLayer.order)

		// Fire the conversion pixels
		jQuery(document).trigger("wpmOrderReceivedPage")

		// Save the order ID on the server to prevent duplicate conversions
		wpm.writeOrderIdToStorage(wpmDataLayer.order.id, wpmDataLayer.order.key, "acr_full_auto")

		// Delete the ACR cookie
		wpm.deleteCookie("pmw_automatic_conversion_recovery")

	} catch (error) {
		wpm.storeData("acr_attempted", true)
		wpm.logError(error)
	}
})

/**
 * Scroll Tracker
 */

// We only can load it once the pmwDataLayer is available
jQuery(document).on("wpmLoad", function () {

	// Only initialize if the scroll tracker is enabled
	if (!wpmDataLayer?.general?.scrollTrackingThresholds || !wpmDataLayer.general.scrollTrackingThresholds.length > 0) return

	(function (wpm, $, undefined) {

		wpm.scrollVisibilityThreshold = wpmDataLayer.general.scrollTrackingThresholds

		// Create an object that contains each key from visibilityBreakPoints and sets each value to false
		wpm.scrollVisibilityThresholdStatus = wpm.scrollVisibilityThreshold.reduce((obj, item) => {
			obj[item] = false
			return obj
		}, {})

	}(window.wpm = window.wpm || {}, jQuery))

	/**
	 * Horizontal Scroll Tracking
	 */
	jQuery(window).on("scroll", (event) => {

		const scrollPosition = jQuery(window).scrollTop()

		// If the scroll reaches a visibility threshold for each threshold in visibilityThresholdStatus that is set to false, trigger an event
		for (const [threshold, value] of Object.entries(wpm.scrollVisibilityThresholdStatus)) {
			if (value === false) {
				const visibilityBreakPoint = (jQuery(document).height() - jQuery(window).height()) * (threshold / 100)
				if (scrollPosition >= visibilityBreakPoint) {
					jQuery(document).trigger("pmwScrollDepth", {
						direction   : "vertical",
						threshold   : threshold,
						position    : scrollPosition,
						positionUnit: "pixels",
					})
					wpm.scrollVisibilityThresholdStatus[threshold] = true
				}
			}
		}
	})

})
