// TODO add enhanced match email hash to uncached pages like cart and purchase confirmation page
// TODO check if more values can be passed to product and category pages
// TODO look into how Pinterest handles variants separately https://developers.pinterest.com/docs/tag/conversion/

/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Pinterest premium functions
 * */

(function (wpm, $, undefined) {

	wpm.getPinterestProductData = product => {

		if (product.isVariation) {
			return {
				product_name      : product.name,
				product_variant_id: product.dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type],
				// product_id        : wpmDataLayer.products[product.parentId].dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type],
				product_id      : product.parentId_dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type],
				product_category: product.category,
				product_variant : product.variant,
				product_price   : product.price,
				product_quantity: product.quantity,
				product_brand   : product.brand,
			}
		} else {
			return {
				product_name    : product.name,
				product_id      : product.dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type],
				product_category: product.category,
				product_price   : product.price,
				product_quantity: product.quantity,
				product_brand   : product.brand,
			}
		}
	}

	wpm.pinterestFormattedOrderItems = () => {

		let orderItems = []

		Object.values(wpmDataLayer.order.items).forEach((item) => {

			let orderItem

			orderItem = {
				product_category: wpmDataLayer.products[item.id].category.join(","),
				// product_brand   : wpmDataLayer.products[key].brand,
				product_quantity: item.quantity,
				product_price   : item.price,
			}

			if (wpmDataLayer?.general?.variationsOutput && 0 !== item.variation_id) {

				orderItem.product_id   = String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type])
				orderItem.product_name = wpmDataLayer.products[item.variation_id].name
				orderItems.push(orderItem)
			} else {

				orderItem.product_id   = String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type])
				orderItem.product_name = wpmDataLayer.products[item.id].name
				orderItems.push(orderItem)
			}
		})

		return orderItems
	}

	// https://developers.pinterest.com/docs/tag/conversion/
	wpm.loadPinterestPixel = () => {

		try {
			wpmDataLayer.pixels.pinterest.loaded = true

			// @formatter:off
			!function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(
				Array.prototype.slice.call(arguments))};var
				n=window.pintrk;n.queue=[],n.version="3.0";var
				t=document.createElement("script");t.async=!0,t.src=e;var
				r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");

			wpm.pinterestLoadEvent();
			pintrk('page');
			// @formatter:on

		} catch (e) {
			console.error(e)
		}
	}

	// https://help.pinterest.com/en/business/article/enhanced-match
	wpm.pinterestLoadEvent = () => {
		try {
			if (
				(
					wpmDataLayer.general.userLoggedIn ||
					"order_received_page" === wpmDataLayer.shop.page_type
				)
				&& wpmDataLayer?.user?.email?.sha256
			) {
				pintrk("load", wpmDataLayer.pixels.pinterest.pixel_id, {em: wpmDataLayer.user.email.sha256})
			} else {
				pintrk("load", wpmDataLayer.pixels.pinterest.pixel_id)
			}
		} catch (e) {
			console.error(e)
		}
	}

	// https://developers.pinterest.com/docs/conversions/best/
	// https://help.pinterest.com/en/business/article/pinterest-tag-parameters-and-cookies
	wpm.getPinterestUserDataFromBrowser = () => {

		let data = {}

		if (
			wpm.getCookie("_pin_unauth")
			|| wpm.getCookie("_pinterest_ct_rt")
			|| wpm.getCookie("_pinterest_ct")
			|| wpm.getCookie("_pinterest_ct_ua")
			|| wpm.getCookie("_pinterest_sess")
		) {
			data.trackingPermitted = true
		}

		// https://developers.pinterest.com/docs/conversions/event/
		// Not 100% if this must be an array or not.
		// On https://developers.pinterest.com/docs/conversions/event/ it is specified as an array.
		// On the setup guide it is specified as a string: https://cln.sh/z5FZsDM8

		// If the epik parameter is present in the URL and is valid, add it to the click_id array
		if (
			wpm.urlHasParameter("epik")
			&& wpm.isValidEpikCookie(wpm.getUrlParameter("epik"))
		) {
			data.click_id = wpm.getUrlParameter("epik")
		}

		if (
			!data.click_id
			&& wpm.getCookie("_epik")
			&& wpm.isValidEpikCookie(wpm.getCookie("_epik"))
		) {
			data.click_id = wpm.getCookie("_epik")
		}

		if (
			!data.click_id
			&& wpm.getCookie("_derived_epik")
			&& wpm.isValidDerivedEpikCookie(wpm.getCookie("_derived_epik"))
		) {
			data.click_id = wpm.getCookie("_derived_epik")
		}

		if (navigator.userAgent) data.client_user_agent = navigator.userAgent

		if (wpmDataLayer?.pixels?.pinterest?.advanced_matching) {
			if (wpmDataLayer?.user?.email?.sha256) data.em = [wpmDataLayer.user.email.sha256]
			if (wpmDataLayer?.user?.phone?.pinterest) data.ph = [wpmDataLayer.user.phone.pinterest]
			if (wpmDataLayer?.user?.first_name?.pinterest) data.fn = [wpmDataLayer.user.first_name.pinterest]
			if (wpmDataLayer?.user?.last_name?.pinterest) data.ln = [wpmDataLayer.user.last_name.pinterest]
			if (wpmDataLayer?.user?.id?.sha256) data.external_id = [wpmDataLayer.user.id.sha256]
			if (wpmDataLayer?.user?.city?.pinterest) data.ct = [wpmDataLayer.user.city.pinterest]
			if (wpmDataLayer?.user?.state?.pinterest) data.st = [wpmDataLayer.user.state.pinterest]
			if (wpmDataLayer?.user?.postcode?.pinterest) data.zp = [wpmDataLayer.user.postcode.pinterest]
			if (wpmDataLayer?.user?.country?.pinterest) data.country = [wpmDataLayer.user.country.pinterest]
		}

		return data
	}

	wpm.isValidEpikCookie = (cookie) => {

		// The following are two valid _epik cookies. Create a regex that checks if a cookie is valid. The function should return true if the cookie is valid and false if it is not.
		// dj0yJnU9aUYyam5aN0N6SjhMZU1pTk1iRTdfUnlNcXRpdHJ1c2UmcD0xJm49QVJEX2VPajVWUWtramc4MUZTWlRfZyZ0PUFBQUFBR1JfV1BN
		// dj0yJnU9dms1bVZPSEFfWEJOazJjQ2pxemExX0NMVy0yUlV6M2EmcD0xJm49RFI4bjBfSjlheE9PMXhrWXltbmFhdyZ0PUFBQUFBR1JfVzVJ

		// Create a regex that checks if a cookie is valid. The function should return true if the cookie is valid and false if it is not.
		(/^dj0yJnU9[a-zA-Z0-9\-\_]{100}/).test(cookie)
	}

	wpm.isValidDerivedEpikCookie = (cookie) => {

		// The following are valid _derived_epik cookies:
		// dj0yJnU9dkV4amp4ZkN4S3c2UzVXTXp3V09OeGVXZHg4WElPczQmbj1oOTBzWUZSZEJHYkNuUXU1TEpvamJRJm09MSZ0PUFBQUFBR1JfV1JnJnJtPTEmcnQ9QUFBQUFHUl9XUmcmc3A9NQ
		// dj0yJnU9VHNZZGQwM25sdXFNcEt6TzlXdFl6WGxraHZfaml1dHkmbj11Y3RHRXNQUzQwSjBIWnZla19HV0xBJm09MSZ0PUFBQUFBR1JfWEFrJnJtPTEmcnQ9QUFBQUFHUl9YQWsmc3A9NQ

		(/^dj0yJnU9[a-zA-Z0-9\-\_]{134}/).test(cookie)
	}

	wpm.pinterestGetProductDataForCapiEvent = (product) => {

		return {
			currency   : product.currency,
			value      : String(product.price * product.quantity),
			content_ids: [String(product.dyn_r_ids[wpmDataLayer.pixels.pinterest.dynamic_remarketing.id_type])],
			num_items  : 1,
			contents   : [{
				item_price: String(product.price),
				quantity  : product.quantity,
			}],
		}
	}

	wpm.getPinterestS2SBaseData = () => {

		return {
			action_source   : "web",
			event_time      : Math.round(Date.now() / 1000),
			event_id        : wpm.getRandomEventId(),
			event_source_url: window.location.href,
			// opt_out         : false,
			// partner_name: "direct",
			user_data: wpm.getPinterestUserDataFromBrowser(),
		}
	}

}(window.wpm = window.wpm || {}, jQuery));
